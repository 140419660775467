import { Middleware, NuxtAppOptions } from '@nuxt/types'
import Cookies from 'js-cookie'
const maintenance: Middleware = ({ route, redirect }: NuxtAppOptions) => {
  if (process.env.MAINTENANCE_TOKEN && route.name !== 'maintenance') {
    if (
      route.query.maintenance === process.env.MAINTENANCE_TOKEN ||
      Cookies.get('maintenance') === process.env.MAINTENANCE_TOKEN
    ) {
      if (!Cookies.get('maintenance')) {
        // 30分間Cookieを通じてメンテナンスを通過できるようになる
        Cookies.set('maintenance', route.query.maintenance, { expires: 1 / 48 })
      }
    } else {
      redirect('/maintenance')
    }
  }
}
export default maintenance
