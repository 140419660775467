
import axios from 'axios'
import { mapState } from 'vuex'
import LoginModal from '~/components/LoginModal.vue'
import Modal from '~/components/Modal.vue'
import NotOpenedPurchasesModaldal from '~/components/NotOpenedPurchasesModal.vue'

export default {
  components: { LoginModal, NotOpenedPurchasesModaldal, Modal },
  middleware: 'auth',
  data() {
    return {
      loginModal: false,
      isLoginable: true,
      notSupportModal: false,
      notOpenedPurchasesModal: false,
      notOpenedPurchases: [],
    }
  },
  computed: mapState({
    user: (state) => state.auth.user,
    mode: (state) => state.common.mode,
  }),
  watch: {
    user: {
      handler: async function (user, _) {
        if (!user) {
          return
        }
        const token = await this.$store.getters['auth/idToken']
        const notOpenedPurchasesResponse = await axios
          .get(`${process.env.HOST}/myPage/notOpendPurchases`, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => res.data)
        this.notOpenedPurchases = notOpenedPurchasesResponse.purchases
        this.notOpenedPurchasesModal = this.notOpenedPurchases.length > 0
      },
    },
  },
  mounted() {
    const notSuppotBrowsersRegex = /line\/|instagram|fbios|fb_iab/
    const userAgent = window.navigator.userAgent.toLowerCase().trim()
    this.notSupportModal = notSuppotBrowsersRegex.test(userAgent)
  },
  methods: {
    openLoginModal() {
      this.loginModal = true
    },
  },
}
