export const state = () => ({
  mode: "GACHA"
})

export const mutations = {
  setMode(state, data) {
    state.mode = data
  },
}

export const actions = {
  setMode({ commit }, data) {
    commit('setMode', data)
  },
}

export const getters = {
  mode(state) {
    return state.mode
  },
}
