import { render, staticRenderFns } from "./NotOpenedPurchasesModal.vue?vue&type=template&id=17cd8ec4&"
import script from "./NotOpenedPurchasesModal.vue?vue&type=script&lang=js&"
export * from "./NotOpenedPurchasesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/opt/build/repo/frontend2/components/Modal.vue').default})
