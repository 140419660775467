
export default {
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: null,
      }),
    },
  },
  methods: {
    errorMessage(errorCode) {
      if (errorCode === 404) {
        return 'ページが見つかりません'
      } else if (errorCode === 400) {
        return '不正なリクエストです'
      } else if (errorCode === 500) {
        return 'サーバーエラーが発生しました'
      }
    },
  },
}
