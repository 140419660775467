import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

const bugsnagClient = Bugsnag.start({
  apiKey: String(process.env.BUGSNAG_API_KEY),
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.NODE_ENV,
})

Bugsnag.getPlugin('vue')?.installVueErrorHandler(Vue)

export default (_ctx: any, inject: any) => {
  inject('bugsnag', bugsnagClient)
}
