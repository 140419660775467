
import Modal from '~/components/Modal.vue'

export default {
  name: 'ModalComponent',
  components: { Modal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSignUp: false,
    }
  },
  methods: {
    async loginWithGoogle() {
      await this.$store.dispatch('auth/signInWithGoogle').then(() => {
        this.$emit('input', false)
      })
    },
    loginWithLine() {
      function generateCouponCode(length) {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        let couponCode = ''

        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * chars.length)
          couponCode += chars[randomIndex]
        }

        return couponCode
      }

      const state = generateCouponCode(20)
      const encodedUrl = encodeURIComponent(
        String(process.env.LINE_LOGIN_REDIRECT_URI)
      )
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.LINE_LOGIN_CHANNEL_ID}&redirect_uri=${encodedUrl}&state=${state}&bot_prompt=aggressive&scope=profile%20openid%20email`

      // await this.$store.dispatch('auth/signInWithLine').then(() => {
      //   this.$emit('input', false)
      // })
    },
  },
}
