import { auth } from '~/plugins/firebase'

export default async function ({ store }) {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (!isAuthenticated) {
    const firebaseUser = await fetchUser()
    if (firebaseUser) {
      await store.dispatch('auth/setFirebaseUser', firebaseUser)
    }
  }
}

async function fetchUser() {
  return await new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      if (user) {
        resolve(user)
      } else {
        resolve(user)
      }
    })
  })
}
