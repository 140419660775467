import { auth } from '~/plugins/firebase'

export default async function ({ store, redirect, route }) {  
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (!isAuthenticated) {
    const firebaseUser = await fetchUser()
    if (firebaseUser) {
      const user = await store.dispatch('auth/setFirebaseUser', firebaseUser)
      if (!user.dateOfBirth && route.path !== '/signup') {
        redirect('/signup')
      }
    } else if (localStorage.getItem('lastVisitedGachaSlug')) {
      redirect(`/${localStorage.getItem('lastVisitedGachaSlug')}`)
    } else {
      redirect('/')
    }
  }
}

async function fetchUser() {
  return await new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      if (user) {
        resolve(user)
      } else {
        resolve(user)
      }
    })
  })
}
