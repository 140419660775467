import firebase from "firebase";
import firebaseErrorsJa from "~/plugins/firebaseErrorsJa";

const config =  {
  apiKey: process.env.FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_CONFIG_APP_ID,
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const firebaseErrors = firebaseErrorsJa;

if (location.hostname === "localhost") {
  // auth.useEmulator('http://localhost:9099/');
}

export { auth, firebase, firebaseErrors }