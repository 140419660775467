import axios from 'axios'
import _ from 'lodash'
import { auth, firebase } from '~/plugins/firebase'

export const state = () => ({
  firebaseUser: null,
  user: null,
  signing: false,
})

export const mutations = {
  setFirebaseUser(state, data) {
    state.firebaseUser = _.cloneDeep(data)
  },
  setUser(state, data) {
    state.user = data
  },
  setSigning(state, data) {
    state.signing = data
  },
}

export const actions = {
  // ログアウト
  async logout({ commit }) {
    await auth.signOut()
    commit('setFirebaseUser', null)
    commit('setUser', null)
  },

  // googleでログイン（初回の場合は会員登録）
  async signInWithGoogle({ commit, dispatch }) {
    commit('setSigning', true)
    const provider = new firebase.auth.GoogleAuthProvider()
    const firebaseUser = await auth
      .signInWithPopup(provider)
      .then(function (result) {
        return result
      })
    const token = await firebaseUser.user.getIdToken()
    if (firebaseUser.additionalUserInfo.isNewUser) {
      await axios.post(
        `${process.env.HOST}/user/create`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      )
      dispatch('setFirebaseUser', firebaseUser.user)
      commit('setSigning', false)
      this.$router.push('/signup')
    } else {
      try {
        await axios.get(`${process.env.HOST}/user`, {
          headers: {
            authorization: token,
          },
        })
      } catch (_) {
        await axios.post(
          `${process.env.HOST}/user/create`,
          {},
          {
            headers: {
              authorization: token,
            },
          }
        )
      }

      dispatch('setFirebaseUser', firebaseUser.user)
      commit('setSigning', false)
    }
  },
  async signInWithTwitter({ _ }) {
    const provider = new firebase.auth.TwitterAuthProvider()
    const firebaseUser = await auth
      .signInWithPopup(provider)
      .then(function (result) {
        return result
      })

    if (firebaseUser.additionalUserInfo.isNewUser) {
      const token = await firebaseUser.user.getIdToken()
      await axios.post(
        `${process.env.HOST}/user/create`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      )
    }
  },
  // lineでログイン（初回の場合は会員登録）
  async signInWithLine({ commit, dispatch, state }, code) {
    commit('setSigning', true)
    try {
      const lineTokenRequestBody = new URLSearchParams({
        grant_type: 'authorization_code',
        code,
        redirect_uri: String(process.env.LINE_LOGIN_REDIRECT_URI),
        client_id: String(process.env.LINE_LOGIN_CHANNEL_ID),
        client_secret: String(process.env.LINE_LOGIN_CHANNEL_SECRET),
      })

      // アクセストークンを取得
      const lineToken = await axios
        .post('https://api.line.me/oauth2/v2.1/token', lineTokenRequestBody)
        .then((res) => res.data)

      // customTokenを取得
      const result = await axios
        .post(
          `${process.env.HOST}/auth/customToken`,
          {
            accessToken: lineToken.access_token,
            idToken: lineToken.id_token,
          },
          state.firebaseUser
            ? {
                headers: {
                  authorization: state.firebaseUser.idToken,
                },
              }
            : {}
        )
        .then((res) => res.data)

      // firebaseのユーザー作成 or ログイン
      const firebaseUser = await auth.signInWithCustomToken(result.customToken)

      dispatch('setFirebaseUser', firebaseUser.user)
      commit('setSigning', false)

      const signInType = result.signInType
      if (signInType === 'signup') {
        console.log('signup')
        this.$router.push('/signup')
      } else if (signInType === 'link') {
        console.log('link')
        this.$router.push('/mypage')
      } else {
        console.log('login')
        const lastVisitedGachaSlug =
          localStorage.getItem('lastVisitedGachaSlug') ?? ''
        if (lastVisitedGachaSlug) {
          this.$router.push(`/${lastVisitedGachaSlug}`)
        } else {
          this.$router.push('/mypage')
        }
      }
    } catch (e) {
      commit('setSigning', false)
      this.$bugsnag.notify(e)
      this.$nuxt.error({
        statusCode: 500,
      })
    }
  },
  async setFirebaseUser({ commit, dispatch }, data) {
    try {
      const firebaseUser = {
        displayName: data.displayName,
        email: data.email,
        uid: data.uid,
        idToken: await data.getIdToken(),
      }
      commit('setFirebaseUser', firebaseUser)
      const response = await axios.get(`${process.env.HOST}/user`, {
        headers: {
          authorization: firebaseUser.idToken,
        },
      })
      const user = response.data
      commit('setUser', user)
      return user
    } catch (_) {
      dispatch('logout')
    }
  },

  async uploadProfilePicture({ state }, file) {
    if (!file) {
      return
    }
    const storage = firebase.storage()
    const storageRef = storage
      .ref()
      .child(`profile_pictures/${state.firebaseUser.uid}.jpg`)
    const url = await storageRef
      .put(file)
      .then((res) => res.ref.getDownloadURL())

    return url
  },

  async updateCurrentUser({ state, commit }) {
    if (state.firebaseUser) {
      const response = await axios.get(`${process.env.HOST}/user`, {
        headers: {
          authorization: state.firebaseUser.idToken,
        },
      })
      const user = response.data
      commit('setUser', user)
    }
  },
}

export const getters = {
  isAuthenticated(state) {
    return !!state.firebaseUser
  },
  user(state) {
    return state.user ? state.user : null
  },
  idToken(state) {
    return state.firebaseUser.idToken ?? null
  },
}
