
import Modal from '~/components/Modal.vue'

export default {
  name: 'ModalComponent',
  components: { Modal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    notOpenedPurchases: {
      type: Array,
      default: () => [],
    },
  },
}
