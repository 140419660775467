
export default {
  name: 'ModalComponent',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
}
